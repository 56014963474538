import { MediaService } from '../media/MediaService'
import { SignallingInterface } from '../signalling/SignallingInterface'

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext
  }
}

interface PeerConnectionParams {
  signalling: SignallingInterface
  to: string
  from: string
  room: string
  onConnect: () => void
  config: RTCConfiguration
}

export class PeerConnection {
  rtcPeerConnection: RTCPeerConnection
  signalling: SignallingInterface
  to: string
  from: string
  room: string
  connected: boolean = false
  remoteAudio?: boolean
  remoteVideo?: boolean
  stream?: MediaStream
  localStream?: MediaStream
  analyserNode?: AnalyserNode | undefined
  onConnect: () => void

  constructor({
    signalling,
    to,
    from,
    room,
    config,
    onConnect
  }: PeerConnectionParams) {
    this.rtcPeerConnection = new RTCPeerConnection(config)
    this.rtcPeerConnection.addEventListener('icecandidate', (event) => {
      this.onIceCandidate(event)
    })
    this.onTrack()
    this.onPcClose()
    this.signalling = signalling
    this.to = to
    this.from = from
    this.room = room
    this.onConnect = onConnect
  }

  toggleRemoteMute() {
    this.stream!.getAudioTracks()[0].enabled =
      !this.stream!.getAudioTracks()[0].enabled
  }

  toggleRemoteVideo() {
    this.stream!.getVideoTracks()[0].enabled =
      !this.stream!.getVideoTracks()[0].enabled
  }

  private async processStream() {
    const stream = await MediaService.createStream()
    if (!stream) {
      return
    }
    this.localStream = stream

    stream.getTracks().forEach((track) => {
      this.rtcPeerConnection.addTrack(track, stream)
    })
  }

  async connect() {
    await this.processStream()
    const offer = await this.createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true
    })
    await this.rtcPeerConnection.setLocalDescription(offer)
    await this.sendOffer(offer)
  }

  private async createOffer(options: RTCOfferOptions = {}) {
    const offer = await this.rtcPeerConnection.createOffer(options)
    return new RTCSessionDescription(offer)
  }

  public async createAnswer(options: RTCOfferOptions = {}) {
    const answer = await this.rtcPeerConnection.createAnswer(options)
    return new RTCSessionDescription(answer)
  }

  public async processOffer(description: RTCSessionDescription) {
    await this.processStream()
    console.log('processOffer', this.rtcPeerConnection)
    await this.rtcPeerConnection.setRemoteDescription(description)
    const answer = await this.createAnswer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true
    })
    await this.rtcPeerConnection.setLocalDescription(
      new RTCSessionDescription(answer)
    )
    this.signalling.sendAnswer({
      room: this.room,
      to: this.to,
      from: this.from,
      video: !!MediaService.getTrackState('video'),
      audio: !!MediaService.getTrackState('audio'),
      description: answer.toJSON()
    })
  }

  public async processAnswer(description: RTCSessionDescription) {
    if (this.rtcPeerConnection.remoteDescription) {
      return
    }
    await this.rtcPeerConnection.setRemoteDescription(description)
  }

  private async sendOffer(offer: RTCSessionDescription) {
    this.signalling.sendOffer({
      room: this.room,
      to: this.to,
      from: this.from,
      description: new RTCSessionDescription(offer)
    })
  }

  public addCandidate(candidate: RTCIceCandidate) {
    return this.rtcPeerConnection.addIceCandidate(candidate)
  }

  private onIceCandidate({ candidate }: RTCPeerConnectionIceEvent) {
    if (!candidate) {
      return
    }

    this.signalling.sendCandidate({
      room: this.room,
      to: this.to,
      from: this.from,
      candidate: {
        candidate: candidate.candidate,
        sdpMid: candidate.sdpMid,
        sdpMLineIndex: candidate.sdpMLineIndex
      }
    })
  }

  private onTrack() {
    this.rtcPeerConnection.addEventListener('track', (track) => {
      this.stream = track.streams[0]

      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)()
      const source = audioContext.createMediaStreamSource(this.stream)
      const analyser = audioContext.createAnalyser()

      // Connect the source to the analyser
      source.connect(analyser)
      this.analyserNode = analyser

      if (!this.connected) {
        this.onConnect()
        this.connected = true
      }
    })
  }

  onPcClose() {
    this.rtcPeerConnection.oniceconnectionstatechange = () => {
      if (
        this.rtcPeerConnection.iceConnectionState === 'closed' ||
        this.rtcPeerConnection.iceConnectionState === 'disconnected' ||
        this.rtcPeerConnection.iceConnectionState === 'failed'
      ) {
        if (this.analyserNode) {
          this.analyserNode.disconnect()
          this.analyserNode = undefined
          console.log(
            'AnalyserNode disconnected due to peer connection closure.'
          )
        }
      }
    }
  }

  close() {
    this.rtcPeerConnection.close()
    MediaService.stopStream()
  }
}
