import { PeerConnection } from '../rtc/PeerConnection'

export class RoomSession {
  room: string
  pc: PeerConnection
  user: string
  audio?: boolean
  video?: boolean

  constructor({
    room,
    pc,
    user,
    audio,
    video
  }: {
    room: string
    pc: PeerConnection
    user: string
    audio?: boolean
    video?: boolean
  }) {
    this.room = room
    this.pc = pc
    this.user = user
    this.audio = audio
    this.video = video
  }
}
