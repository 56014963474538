import { RoomSession } from './RoomSession'
import mitt from 'mitt'
import { Room, UserType } from './Room'
import { SignallingInterface } from '../signalling/SignallingInterface'
import { StartRoomInterface } from './StartRoomInterface'

export class StartRoomService implements StartRoomInterface {
  sessions: RoomSession[] = []

  startRoom(
    signalling: SignallingInterface,
    roomId: string,
    from: string,
    pcConfig: RTCConfiguration,
    users?: string[],
    onLeft?: (session: RoomSession) => void,
    onRoomCreated?: (room: Room) => void,
    onConnect?: (session: RoomSession) => void,
    userType?: UserType,
    mediaStreamConstraints?: MediaStreamConstraints | undefined
  ): void {
    users = users || []
    const emitter = mitt()
    const room = new Room({
      emitter,
      id: roomId,
      signalling: signalling,
      pcConfig: pcConfig,
      users,
      from,
      onConnect: (session) => {
        this.sessions = [...this.sessions, session]
        if (typeof onConnect === 'function') {
          onConnect(session)
        }
      },
      onLeft: (user) => {
        if (typeof onLeft === 'function') {
          const session = this.sessions.find((s) => s.user === user)
          onLeft(session!)
        }
        this.sessions = this.sessions.filter((s) => s.user !== user)
      },
      type: userType || UserType.user
    })
    emitter.emit('roomCreated', room)
    room.connect(mediaStreamConstraints).then(() => {
      if (onRoomCreated) {
        onRoomCreated(room)
      }
    })
  }
}
