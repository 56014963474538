import {
  SignallingCandidate,
  SignallingData,
  SignallingInterface,
  SignallingLeft,
  SignallingMediaState,
  SignallingState,
  SignallingTracksState,
  SignallingTransportInterface
} from './SignallingInterface'

export class SignallingService implements SignallingInterface {
  transport: SignallingTransportInterface
  joined: boolean = false
  prefix: string = ''

  constructor(transport: SignallingTransportInterface, prefix?: string) {
    this.transport = transport
    this.prefix = prefix || this.prefix
  }

  joinRoom(room: any) {
    if (this.joined) {
      return
    }
    this.joined = true
    return this.transport.join(room)
  }

  private getEvent(event: string) {
    return this.prefix + event
  }

  sendAnswer(data: SignallingData): void {
    this.transport.emit(data.room, this.getEvent('answer'), data)
  }

  sendCandidate(data: SignallingCandidate): void {
    this.transport.emit(data.room, this.getEvent('candidate'), data)
  }

  sendOffer(data: SignallingData): void {
    this.transport.emit(data.room, this.getEvent('offer'), data)
  }

  onOffer(room: string, handler: (data: SignallingData) => void) {
    this.transport.on(room, this.getEvent('offer'), handler)
  }

  onAnswer(room: string, handler: (data: SignallingData) => void) {
    this.transport.on(room, this.getEvent('answer'), handler)
  }

  onCandidate(room: string, handler: (data: SignallingCandidate) => void) {
    this.transport.on(room, this.getEvent('candidate'), handler)
  }

  onMute(room: string, handler: (data: SignallingMediaState) => void) {
    this.transport.on(room, this.getEvent('mute'), handler)
  }

  onVideo(room: string, handler: (data: SignallingMediaState) => void) {
    this.transport.on(room, this.getEvent('video'), handler)
  }

  onAllMute(room: string, handler: (data: SignallingState) => void) {
    this.transport.on(room, this.getEvent('all-mute'), handler)
  }

  onAllVideo(room: string, handler: (data: SignallingState) => void) {
    this.transport.on(room, this.getEvent('all-video'), handler)
  }

  onLeft(room: string, handler: (data: SignallingLeft) => void) {
    this.transport.on(room, this.getEvent('left'), handler)
  }

  onTracksState(room: any, handler: (data: SignallingTracksState) => void) {
    this.transport.on(room, this.getEvent('tracksState'), handler)
  }

  mute(room: string, user: string, state: boolean) {
    this.transport.emit(room, this.getEvent('mute'), {
      state,
      user
    })
  }

  video(room: string, user: string, state: boolean) {
    this.transport.emit(room, this.getEvent('video'), {
      state,
      user
    })
  }

  allMute(room: string, state: boolean) {
    this.transport.emit(room, this.getEvent('all-mute'), {
      state
    })
  }

  allVideo(room: string, state: boolean) {
    this.transport.emit(room, this.getEvent('all-video'), {
      state
    })
  }

  sendTracksState(room: string, data: SignallingTracksState) {
    this.transport.emit(room, this.getEvent('tracksState'), {
      video: data.video,
      audio: data.audio,
      user: data.user
    })
  }
}
