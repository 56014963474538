import {
  SignallingCandidate,
  SignallingData,
  SignallingInterface,
  SignallingLeft,
  SignallingMediaState,
  SignallingState
} from '../signalling/SignallingInterface'
import { PeerConnection } from '../rtc/PeerConnection'
import { MediaService } from '../media/MediaService'
import { RoomSession } from './RoomSession'
import { Emitter } from 'mitt'

export interface RemoteCandidate {
  data: SignallingCandidate
}

export enum UserType {
  admin = 'ADMIN',
  user = 'USER'
}

interface ConstructorParams {
  id: string
  signalling: SignallingInterface
  pcConfig: RTCConfiguration
  onConnect: (session: RoomSession) => void
  onLeft: (user: string) => void
  users?: string[]
  from: string
  type: UserType
  emitter: Emitter<any>
}

export enum AudioState {
  all,
  muteAll
}

export enum VideoState {
  all,
  disableAll
}

export class Room {
  readonly id: string
  private readonly from: string
  private readonly signalling: SignallingInterface
  private sessions: RoomSession[] = []
  private candidates: RemoteCandidate[] = []
  private users: string[]
  private readonly pcConfig: RTCConfiguration
  private readonly onConnect: (session: RoomSession) => void
  private readonly onLeft: (user: string) => void
  private audioState: AudioState = AudioState.all
  private videoState: VideoState = VideoState.all
  private disabledVideoUsers: string[] = []
  private readonly type: UserType
  public emitter: Emitter<any>

  private mediaState: Map<string, { audio: boolean; video: boolean }>

  constructor({
    id,
    signalling,
    pcConfig,
    from,
    onConnect,
    onLeft,
    users = [],
    type,
    emitter
  }: ConstructorParams) {
    console.log('Room con')

    this.pcConfig = pcConfig
    this.id = id
    this.signalling = signalling
    this.users = users
    this.onConnect = onConnect
    this.onLeft = onLeft
    this.from = from
    this.type = type
    this.emitter = emitter
    this.mediaState = new Map<string, { audio: boolean; video: boolean }>()
  }

  async connect(mediaStreamConstraints?: MediaStreamConstraints | undefined) {
    MediaService.createStream(mediaStreamConstraints).then(() => {
      this.listenOffers()
      this.listenAnswers()
      this.listenCandidate()
      this.listenLeft()
      this.listenVideo()
      this.listenAudio()
      this.listenTracksState()
      this.signalling.joinRoom(this.id)
      this.users.forEach((user) => {
        this.connectTo(user)
      })
    })
  }

  toggleMyVideo() {
    if (this.videoState === VideoState.disableAll) {
      return
    }

    if (!MediaService.stream) {
      return
    }

    const state = MediaService.toggleTrack('video')
    this.signalling.video(this.id, this.from, state)
  }

  toggleMyAudio() {
    if (this.audioState === AudioState.muteAll) {
      return
    }

    if (!MediaService.stream) {
      return
    }

    const state = MediaService.toggleTrack('audio')
    this.signalling.mute(this.id, this.from, state)
  }

  toggleUserAudio(user: string) {
    const session = this.findSessionByUser(user)
    console.log(session)
    if (!session) {
      return
    }

    if (this.type === UserType.user) {
      session.pc.toggleRemoteMute()
      return
    }

    session.audio = !session.audio
    this.signalling.mute(this.id, user, session.audio)
  }

  toggleUserVideo(user: string) {
    const session = this.findSessionByUser(user)
    if (!session) {
      return
    }

    if (this.type === UserType.user) {
      session.pc.toggleRemoteVideo()
      return
    }

    if (this.videoState === VideoState.disableAll) {
      return
    }

    if (!session.video) {
      this.disabledVideoUsers = this.disabledVideoUsers.filter(
        (u) => u !== user
      )
    } else {
      this.disabledVideoUsers.push(user)
    }
    session.video = !session.video
    this.signalling.video(this.id, user, session.video)
  }

  toggleAllVideo() {
    if (this.type === UserType.user) {
      this.sessions.forEach((session) => {
        session.pc.toggleRemoteVideo()
      })
      return
    }

    const state = this.videoState === VideoState.disableAll

    this.videoState =
      this.videoState === VideoState.disableAll
        ? VideoState.all
        : VideoState.disableAll
    MediaService.setEnableTrackByKind('video', state)
    this.signalling.allVideo(this.id, state)
  }

  toggleAllAudio() {
    if (this.type === UserType.user) {
      this.sessions.forEach((session) => {
        session.pc.toggleRemoteMute()
      })
      return
    }

    const state = this.audioState === AudioState.muteAll
    MediaService.setEnableTrackByKind('audio', state)
    this.audioState =
      this.audioState === AudioState.muteAll
        ? AudioState.all
        : AudioState.muteAll

    this.signalling.allMute(this.id, state)
  }

  removeStream() {
    MediaService.removeStream()
  }

  private addSession(session: RoomSession) {
    const userMediaState = this.mediaState.get(session.user)

    if (userMediaState) {
      session.audio = userMediaState.audio
      session.video = userMediaState.video
      this.emitter.emit('session-updated', session)
      this.mediaState.delete(session.user)
    }

    this.sessions.push(session)
  }

  private async connectTo(to: string) {
    console.log('connectTo')

    const pc = new PeerConnection({
      signalling: this.signalling,
      room: this.id,
      to,
      from: this.from,
      config: this.pcConfig,
      onConnect: () => {
        console.log('pc connected')

        const session = this.findSessionByUser(to)
        if (!session) {
          return
        }

        this.onConnect(session)
      }
    })
    this.addSession(
      new RoomSession({
        pc,
        room: this.id,
        user: to
      })
    )
    await pc.connect()
  }

  private listenAnswers() {
    const from = this.from
    this.signalling.onAnswer(this.id, async (data) => {
      if (data.to !== from) {
        return
      }
      await this.answerHandler(data)
    })
  }

  private async answerHandler(data: SignallingData) {
    const session = this.findSessionByUser(data.from)
    if (!session) {
      return
    }
    session.pc.remoteAudio = data.audio
    session.pc.remoteVideo = data.video
    session.video = data.video
    session.audio = data.audio
    this.emitter.emit('session-updated', session)
    await session.pc.processAnswer(data.description)
    this.processRemoteCandidates(session.pc, data)
  }

  private listenOffers() {
    const from = this.from
    this.signalling.onOffer(this.id, async (data) => {
      if (data.to !== from) {
        return
      }
      await this.offerHandler(data)
    })
  }

  private async offerHandler(data: SignallingData) {
    const pc = new PeerConnection({
      signalling: this.signalling,
      room: this.id,
      to: data.from,
      from: this.from,
      config: this.pcConfig,
      onConnect: () => {
        const session = this.findOrCreateSession(data, pc)
        this.onConnect(session)
        this.signalling.sendTracksState(this.id, {
          audio: MediaService.getTrackState('audio'),
          video: MediaService.getTrackState('video'),
          user: this.from
        })
      }
    })

    await pc.processOffer(new RTCSessionDescription(data.description))
    this.findOrCreateSession(data, pc)
    this.processRemoteCandidates(pc, data)
  }

  private findOrCreateSession(data: SignallingData, pc: PeerConnection) {
    const foundSession = this.findSessionByUser(data.from)
    if (foundSession) {
      return foundSession
    }
    const session = new RoomSession({
      pc,
      room: this.id,
      user: data.from
    })

    this.addSession(session)

    return session
  }

  private async processRemoteCandidates(
    pc: PeerConnection,
    data: SignallingData
  ) {
    const candidates = this.candidates.filter(
      (c) =>
        c.data.from === data.from &&
        data.room === c.data.room &&
        data.to === this.from
    )

    if (!candidates.length) {
      return
    }

    for (const c of candidates) {
      await pc.addCandidate(
        new RTCIceCandidate({
          candidate: c.data.candidate.candidate,
          sdpMid: c.data.candidate.sdpMid,
          sdpMLineIndex: c.data.candidate.sdpMLineIndex
        })
      )
    }

    this.candidates = this.candidates.filter(
      (c) =>
        c.data.from !== data.from &&
        data.room !== c.data.room &&
        data.to !== this.from
    )
  }

  private listenCandidate() {
    this.signalling.onCandidate(this.id, async (data) => {
      await this.candidateHandler(data)
    })
  }

  private async candidateHandler(data: SignallingCandidate) {
    const session = this.findSessionByUser(data.from)
    if (!session || !session.pc.rtcPeerConnection.remoteDescription) {
      this.candidates.push({
        data: data
      })
    } else {
      if (!data.candidate.candidate) {
        return
      }
      await session.pc.addCandidate(
        new RTCIceCandidate({
          candidate: data.candidate.candidate,
          sdpMid: data.candidate.sdpMid,
          sdpMLineIndex: data.candidate.sdpMLineIndex
        })
      )
    }
  }

  private listenLeft() {
    this.signalling.onLeft(this.id, (data) => {
      this.leftHandler(data)
    })
  }

  private listenTracksState() {
    this.signalling.onTracksState(this.id, (data) => {
      const session = this.sessions.find((s) => s.user === data.user)
      if (!session) {
        return
      }
      session.video = data.video
      session.audio = data.audio
      this.emitter.emit('session-updated', session)
    })
  }

  private leftHandler(data: SignallingLeft) {
    this.sessions = this.sessions.filter((s) => s.user !== data.user)
    this.onLeft(data.user)
  }

  private listenVideo() {
    this.signalling.onVideo(this.id, (data) => {
      this.videoHandler(data)
    })
    this.signalling.onAllVideo(this.id, (data) => {
      this.allVideoHandler(data)
    })
  }

  private allVideoHandler(data: SignallingState) {
    this.emitter.emit('video', data.state)
    MediaService.setEnableTrackByKind('video', data.state)
  }

  private videoHandler(data: SignallingMediaState) {
    this.mediaHandler(data, 'video')
  }

  private mediaHandler(
    data: SignallingMediaState,
    type: 'audio' | 'video',
    cb?: (data: SignallingMediaState) => void
  ) {
    const session = this.findSessionByUser(data.user)

    if (session) {
      session[type] = data.state
      this.emitter.emit('session-updated', session)
    } else {
      const userMediaState = this.mediaState.get(data.user) ?? {
        audio: false,
        video: false
      }

      this.mediaState.set(data.user, { ...userMediaState, [type]: data.state })
    }

    if (typeof cb === 'function') {
      cb(data)
    }

    if (data.user !== this.from) {
      return
    }

    if (type === 'audio') {
      this.emitter.emit('my-audio-session-updated', data.state)
    }
    MediaService.setEnableTrackByKind(type, data.state)
  }

  private listenAudio() {
    this.signalling.onMute(this.id, (data) => {
      this.audioHandler(data)
    })
    this.signalling.onAllMute(this.id, (data) => {
      this.allMuteHandler(data)
    })
  }

  private allMuteHandler(data: SignallingState) {
    this.emitter.emit('audio', data.state)
    MediaService.setEnableTrackByKind('audio', data.state)
  }

  private audioHandler(data: SignallingMediaState) {
    this.mediaHandler(data, 'audio', () => {})
  }

  private findSessionByUser(user: string) {
    return this.sessions.find((s) => s.user === user)
  }
}
