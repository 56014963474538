declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext
  }
}

export class MediaService {
  static stream?: MediaStream
  static error?: null
  static analyserNode?: AnalyserNode | undefined

  private static constraintsFull = {
    audio: true,
    video: {
      facingMode: 'user',
      // medium
      // width: { ideal: 512 },
      // height: { ideal: 380 },
      // low
      width: { ideal: 341 },
      height: { ideal: 341 / 1.777 },
      // old values (high)
      // width: { ideal: 1024 },
      // height: { ideal: 760 }
      frameRate: { max: 28 }
    }
  }

  static async createStream(
    constraints = {}
  ): Promise<MediaStream | undefined> {
    if (this.stream) {
      return this.stream
    }

    const devices = await navigator.mediaDevices.enumerateDevices()

    const hasCamera = devices.find((d) => d.kind === 'videoinput')
    const hasMic = devices.find((d) => d.kind === 'audioinput')

    try {
      const constraintsMerged = {
        ...{
          ...this.constraintsFull,
          video: hasCamera ? this.constraintsFull.video : false,
          audio: hasMic ? this.constraintsFull.audio : false
        },
        ...constraints
      }

      this.stream = await navigator.mediaDevices.getUserMedia(constraintsMerged)

      this.stream.getTracks().forEach((track) => {
        if (track.kind === 'audio') {
          track.enabled = false
        }
        if (track.kind === 'video') {
          track.enabled = false
        }
      })

      this.error = null
      return this.stream
    } catch (e) {
      this.error = e
    }
    return undefined
  }

  static stopStream(): void {
    if (!this.stream) {
      return
    }
    this.stream!.getTracks().forEach((t) => t.stop())
  }

  static createAnalyserNode(): AnalyserNode | undefined {
    if (!this.stream) {
      return undefined
    }

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)()

    const source = audioContext.createMediaStreamSource(this.stream)

    const analyser = audioContext.createAnalyser()

    source.connect(analyser)
    this.analyserNode = analyser

    return this.analyserNode
  }

  static toggleTrack(type: 'audio' | 'video') {
    const track = MediaService.stream!.getTracks().find((t) => t.kind === type)
    if (!track) {
      return false
    }
    track.enabled = !track!.enabled
    console.log('setEnableTrackByKind', type, track!.enabled)
    return track!.enabled
  }

  static getTrackState(kind: 'audio' | 'video') {
    const track = MediaService.getTrackByKind(kind)
    if (!track) {
      return false
    }
    return track!.enabled
  }

  static getTrackByKind(kind: 'audio' | 'video') {
    if (!MediaService.stream) {
      return null
    }
    return MediaService.stream!.getTracks().find((t) => t!.kind === kind)
  }

  static setEnableTrackByKind(kind: 'audio' | 'video', state: boolean) {
    const track = this.getTrackByKind(kind)
    if (!track) {
      return
    }
    console.log('setEnableTrackByKind', kind, state)
    track!.enabled = state
  }

  static removeAnalyserNode() {
    if (!this.analyserNode) {
      return
    }

    this.analyserNode.disconnect()
    this.analyserNode = undefined
  }

  static removeStream() {
    if (!this.stream) {
      return
    }
    this.removeAnalyserNode()
    MediaService.stream!.getTracks().forEach((t) => {
      t.stop()
    })
    this.stream = undefined
  }
}
